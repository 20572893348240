var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mew-component--why-mew" }, [
    _c(
      "div",
      { staticClass: "desktop-content d-none d-lg-block" },
      [
        _c("div", { staticClass: "py-6" }),
        _c(
          "v-container",
          [
            _c(
              "v-sheet",
              { attrs: { color: "transparent", "max-width": "400px" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "mew-heading-3 text-uppercase textLight--text mb-1",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("lokalise")(
                            _vm.$t("home.about-mew.subheading"),
                            "home.about-mew.subheading"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("h1", { staticClass: "mb-9" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("lokalise")(
                          _vm.$t("home.about-mew.heading"),
                          "home.about-mew.heading"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("mew-info-card", {
                  staticClass: "d-flex align-end block-box-shadow",
                  attrs: {
                    title: _vm.$t("home.about-mew.card-one.title"),
                    desc: _vm.$t("home.about-mew.card-one.text"),
                    icon: require("@/assets/images/icons/icon-faces-mew.svg"),
                  },
                }),
                _c("div", { staticClass: "pa-4" }),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c("mew-info-card", {
                      staticClass: "full-height",
                      attrs: {
                        "max-width": "600",
                        title: _vm.$t("home.about-mew.card-two.title"),
                        desc: _vm.$t("home.about-mew.card-two.text"),
                      },
                    }),
                    _c("div", { staticClass: "pa-4" }),
                    _c("mew-info-card", {
                      staticClass: "full-height",
                      attrs: {
                        "max-width": "600",
                        title: _vm.$t("home.about-mew.card-three.title"),
                        desc: _vm.$t("home.about-mew.card-three.text"),
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "pa-4" }),
                _c("mew-info-card", {
                  attrs: {
                    title: _vm.$t("home.about-mew.card-four.title"),
                    desc: _vm.$t("home.about-mew.card-four.text"),
                    link: {
                      title: _vm.$t("home.about-mew.card-four.link"),
                      url: "https://www.mewwallet.com/",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "titleIconContainer",
                      fn: function () {
                        return [
                          _c("img", {
                            staticClass: "ml-2 mr-1",
                            attrs: {
                              src: require("@/assets/images/icons/icon-appstore-apple-dark.svg"),
                              alt: "Apple app store",
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/icons/icon-appstore-android-dark.svg"),
                              alt: "Android store",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cardImg",
                      fn: function () {
                        return [
                          _c("img", {
                            staticClass: "card-img",
                            attrs: {
                              width: "200",
                              src: require("@/assets/images/snippets/bg-mew-wallet.png"),
                              alt: "MEW wallet",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "py-12" }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mobile-content d-block d-lg-none" },
      [
        _c("div", { staticClass: "py-7" }),
        _c(
          "v-container",
          [
            _c(
              "v-sheet",
              {
                staticClass: "mx-auto",
                attrs: { color: "transparent", "max-width": "500px" },
              },
              [
                _c(
                  "v-sheet",
                  {
                    staticClass: "mb-9",
                    attrs: { color: "transparent", "max-width": "400px" },
                  },
                  [
                    _c(
                      "h5",
                      {
                        staticClass:
                          "font-weight-bold text-uppercase textLight--text mb-2",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("home.about-mew.subheading")) +
                            " "
                        ),
                      ]
                    ),
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("home.about-mew.heading"))),
                    ]),
                  ]
                ),
                _c("mew-info-card", {
                  staticClass: "d-flex align-end block-box-shadow mx-auto mb-6",
                  attrs: {
                    title: _vm.$t("home.about-mew.card-one.title"),
                    desc: _vm.$t("home.about-mew.card-one.text"),
                    icon: require("@/assets/images/icons/icon-faces-mew.svg"),
                  },
                }),
                _c("mew-info-card", {
                  staticClass: "full-height mx-auto mb-6",
                  attrs: {
                    title: _vm.$t("home.about-mew.card-two.title"),
                    desc: _vm.$t("home.about-mew.card-two.text"),
                  },
                }),
                _c("mew-info-card", {
                  staticClass: "full-height mx-auto mb-6",
                  attrs: {
                    title: _vm.$t("home.about-mew.card-three.title"),
                    desc: _vm.$t("home.about-mew.card-three.text"),
                  },
                }),
                _c("mew-info-card", {
                  staticClass: "mx-auto",
                  attrs: {
                    title: _vm.$t("home.about-mew.card-four.title"),
                    desc: _vm.$t("home.about-mew.card-four.text"),
                    link: {
                      title: _vm.$t("home.about-mew.card-four.link"),
                      url: "https://www.mewwallet.com/",
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "titleIconContainer",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "ml-2" }, [
                            _c("img", {
                              staticClass: "mr-1",
                              attrs: {
                                src: require("@/assets/images/icons/icon-appstore-apple-dark.svg"),
                                alt: "Apple app store",
                              },
                            }),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/icons/icon-appstore-android-dark.svg"),
                                alt: "Android store",
                              },
                            }),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "cardImg",
                      fn: function () {
                        return [
                          _c("img", {
                            staticClass: "card-img",
                            attrs: {
                              width: "200",
                              src: require("@/assets/images/snippets/bg-mew-wallet.png"),
                              alt: "MEW wallet",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "py-7" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }